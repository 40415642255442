import { render, staticRenderFns } from "./RftSuppliersPending.vue?vue&type=template&id=27085668&"
import script from "./RftSuppliersPending.vue?vue&type=script&lang=ts&"
export * from "./RftSuppliersPending.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports